import React, { useState, useEffect } from 'react';
import { Treebeard, decorators } from 'react-treebeard';
import { createTheme, Badge, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faUndo } from '@fortawesome/free-solid-svg-icons';
import CentralCalls from '../../centralCalls';
// const data = [
//     {
//         name: 'child1',
//         procedure: 'procedure1'
//     },
//     {
//         name: 'child2',
//         procedure: 'procedure2',
//         children: [
//             {
//                 name: 'child2-1',
//                 procedure: 'procedure2-1'
//             },
//             {
//                 name: 'child2-2',
//                 procedure: 'procedure2-1',
//                 children: [
//                     {
//                         name: 'child2-2-1',
//                         procedure: 'heyooo'
//                     }
//                 ]
//             }
//         ]
//     }
// ];

const theme = createTheme({
    palette: {
        primary: {
            main: '#f8f9f8',
        },
        secondary: {
            main: '#18f318',
        },
    },
    spacing: 8,
});

const useStyles = makeStyles(() => ({
    tree: {
        fontFamily: 'Arial, sans-serif',
        border: '1px solid #dee2e6',
        borderRadius: 4,
        overflow: 'hidden',
        margin: '16px 0',
    },
    treeNode: {
        padding: '8px 8px',
        cursor: 'pointer',
        borderBottom: '1px solid #dee2e6',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between', // Add this line
        '&:hover': {
            backgroundColor: 'pink',
        },
    },
    treeNodeActive: {
        color: '#ffffff',
        // '& > $treeNodeName': {
        //     fontWeight: 'bold',
        // },
    },
    treeNodeNameWrapper: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        marginLeft: '8px'
    },
    treeNodeName: {
        marginRight: '15px',
        marginLeft: '15px',
    },
    procedureButton: {
        padding: '4px 8px',
        backgroundColor: '#007bff',
        color: '#ffffff',
        border: 'none',
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#B3CDE6',
        },
    },
    toggleIcon: {
        marginRight: '8px',
        cursor: 'pointer',
    },
    undoIcon: {
        marginRight: '25px'
    }
}));


const TreeViewComponent = ({ sp_name, params, rowClickFunction, sortOrder }) => {
    const classes = useStyles();
    const [cursor, setCursor] = useState(null);
    const [data, setData] = useState([]);
    const onToggle = (node, toggled) => {
        if (cursor) {
            cursor.active = false;
        }
        node.active = true;
        node.toggled = toggled;
        setCursor({ ...node });
    };
    useEffect(() => {
        const runSp = async () => {
            if (sp_name && params) {
                console.log('running...')
                console.log(params);
                const runData = await CentralCalls.callStoredProcedureWithParams(sp_name, params);
                console.log(runData);
                const sortedData = sortData(runData, sortOrder);
                setData(sortedData);
            }
        }
        runSp();
    }, [sp_name, params, sortOrder]);
    const handleProcedureClick = (procedure, node) => {
        rowClickFunction(node);
    };
    const sortData = (data, sortOrder) => {
        return [...data].sort((a, b) => {
            for (let key in sortOrder) {
                // Get values for comparison
                const valueA = a[key];
                const valueB = b[key];
                // Handle null, undefined, or empty string values
                if (valueA == null || valueA === undefined || valueA === '') {
                    if (valueB == null || valueB === undefined || valueB === '') {
                        // Both values are null/undefined/empty, continue to the next key
                        continue;
                    }
                    // Only valueA is null/undefined/empty, place it after valueB
                    return sortOrder[key] === 'asc' ? 1 : -1;
                }

                if (valueB == null || valueB === undefined || valueB === '') {
                    // Only valueB is null/undefined/empty, place it after valueA
                    return sortOrder[key] === 'asc' ? -1 : 1;
                }

                // Compare non-null, non-undefined, and non-empty string values
                if (valueA < valueB) {
                    return sortOrder[key] === 'asc' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortOrder[key] === 'asc' ? 1 : -1;
                }
            }
            return 0;
        });
    };
    const CustomHeader = ({ style, node }) => {
        return (
            <div
                className={`${classes.treeNode} ${node.active ? classes.treeNodeActive : ''}`}
                style={{ ...style.base, backgroundColor: 'inherit' }}
                onClick={() => onToggle(node, !node.toggled)}
            >
                {node.children && (
                    <FontAwesomeIcon
                        icon={node.toggled ? faChevronDown : faChevronRight}
                        className={classes.toggleIcon}
                        onClick={(e) => {
                            e.stopPropagation();
                            onToggle(node, !node.toggled);
                        }}
                    />
                )}
                <div className={classes.treeNodeNameWrapper} onClick={() => onToggle(node, !node.toggled)}>
                    {node.childCount && (
                        <Badge
                            key={node.oid}
                            showZero
                            max={999}
                            onClick={(e) => {
                                e.stopPropagation();
                                onToggle(node, !node.toggled);
                            }}
                            badgeContent={node.childCount}
                            color="secondary"
                            sx={{
                                '& .MuiBadge-badge': {
                                    backgroundColor: '#0b426b',
                                    color: 'white',
                                },
                            }}
                        ></Badge>
                    )}
                    <span
                        className={classes.treeNodeName}
                        onClick={(e) => {
                            e.stopPropagation();
                            onToggle(node, !node.toggled);
                        }}
                    >
                        {node.name}
                    </span>


                </div>
                {node.oid && (
                    <FontAwesomeIcon className={classes.undoIcon}
                        icon={faUndo}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleProcedureClick(node.procedure, node);
                        }}
                    />
                )}
            </div>
        );
    };

    const customDecorators = {
        ...decorators,
        Header: CustomHeader,
    };

    const customStyle = {
        tree: {
            base: {
                listStyle: 'none',
                backgroundColor: 'whitesmoke',
                margin: 0,
                padding: 0,
                color: '#000',
                fontFamily: 'Arial, sans-serif',

            },
            node: {
                base: {
                    position: 'relative',
                },
                link: {
                    cursor: 'pointer',
                    position: 'relative',
                    padding: '0px 5px',
                    display: 'block',
                },
                activeLink: {
                    background: 'whitesmoke',
                },
                toggle: {
                    base: {
                        position: 'relative',
                        verticalAlign: 'middle',
                        marginLeft: '-5px',
                        height: '24px',
                        width: '24px',
                        display: 'none'
                    },
                    wrapper: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        margin: '-7px 0 0 -7px',
                        height: '14px',
                        display: 'none'
                    },
                    height: 0,
                    width: 0,
                    arrow: {
                        display: 'none', // Hide the original arrow
                    },
                },
                header: {
                    base: {
                        display: 'flex',
                        alignItems: 'center',
                        color: '#000',
                    },
                    connector: {
                        width: '2px',
                        height: '12px',
                        borderLeft: 'solid 2px black',
                        borderBottom: 'solid 2px black',
                        position: 'absolute',
                        top: '0px',
                        left: '-21px',
                    },
                    title: {
                        lineHeight: '24px',
                        verticalAlign: 'middle',
                    },
                },
                subtree: {
                    listStyle: 'none',
                    paddingLeft: '19px', // Adjust padding to ensure correct indentation
                },
                loading: {
                    color: '#E2C089',
                },
            },
        },
    };
    console.log('components..')
    console.log(data);
    return (
        <ThemeProvider theme={theme}>
            { data && data.length > 0 && (
            <div className={classes.tree}>
                <Treebeard
                    data={data}
                    onToggle={onToggle}
                    decorators={customDecorators}
                    style={customStyle}
                />
            </div>
            )}
        </ThemeProvider>
    );
};

export default TreeViewComponent;
