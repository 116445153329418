import React, { useState, useEffect } from 'react';
import './LotFilter.css'; // Import the CSS file for component styling
import CentralCalls from '../../centralCalls';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const LotFilter = ({ onLotChange }) => {
    const [builders, setBuilders] = useState([]);
    const [selectedBuilder, setSelectedBuilder] = useState('');
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [selectedNeighborhood, setSelectedNeighborhood] = useState('');
    const [lots, setLots] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedLot, setSelectedLot] = useState('');
    const [recentLots, setRecentLots] = useState([]);
    const [showRecentLots, setShowRecentLots] = useState(false);

    const location = useLocation();
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };
    const query = useQuery();
    //const [lotOidFromUrl, setLotOidFromUrl] = useState(query.get('lot_oid'));
    // Inside your LotFilter component
    const toggleShowRecentLots = (show) => {
        setShowRecentLots(show);
    };

    // Function to parse query parameters



    const lotOidFromUrl = query.get('lot_oid');
    useEffect(() => {
        console.log('fecting...')
        fetchRecentLots();
    }, []);

    const fetchRecentLots = async () => {
        try {
            const response = await CentralCalls.callStoredProcedureNoParams('sp_getRecentLots');
            console.log('lots...')
            console.log(response);
            setRecentLots(response);
        } catch (error) {
            console.error('Failed to fetch recent lots:', error);
        }
    };

    // Fetch builders from API
    useEffect(() => {
        if (!lotOidFromUrl) {
            loadBuilders();
        }
    }, []);
    const loadLotFromUrl = async (id) => {
        try {
            console.log(id);
            const rows = await CentralCalls.fetchTableData('vw_lot_info', { 'lot_oid': id });
            console.log(rows);
            console.log('...rows');
            if (rows && rows.length > 0) {
                setLoading(true);
                const { builder_oid, neighborhood_oid } = rows[0];
                await setSelectedLot(id);
                onLotChange(id);
                await loadBuilders();
                setSelectedBuilder(builder_oid);
                await loadNeighborhoods(builder_oid);
                setSelectedNeighborhood(neighborhood_oid);
                await loadLots(neighborhood_oid);
                setLoading(false);
            }

        } catch (error) {
            console.error('Failed to fetch initial data:', error);
        }
    }
    useEffect(() => {
        const fetchInitialData = async () => {
            if (lotOidFromUrl) {
                loadLotFromUrl(lotOidFromUrl);
            }
            setLoading(false);
        };
        // Fetch initial lot, builder, and neighborhood info based on the lot_oid URL parameter
        fetchInitialData();
    }, [lotOidFromUrl]);

    const loadBuilders = async () => {
        const rows = await CentralCalls.fetchTableDataNoWhere('builder');
        setBuilders(rows);
    };
    const handleBuilderChange = async (event) => {
        const selectedBuilder = event.target.value;
        setSelectedBuilder(selectedBuilder);
        setSelectedNeighborhood('');
        setSelectedLot('');
        loadNeighborhoods(selectedBuilder);
        // try {
        //     const response = await fetch('/api/table_select', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({
        //             tableName: 'neighborhood',
        //             whereClause: { builder_oid: selectedBuilder },
        //         }),
        //     });
        //     const data = await response.json();
        //     setNeighborhoods(data);
        // } catch (error) {
        //     console.error('Failed to fetch neighborhoods:', error);
        // }
    };
    const loadNeighborhoods = async (builder) => {
        const rows = await CentralCalls.fetchTableData('neighborhood', { 'builder_oid': builder });
        setNeighborhoods(rows);
    }
    const loadLots = async (neighborhood) => {
        const rows = await CentralCalls.fetchTableData('lot', { 'neighborhood_oid': neighborhood, 'deleted': 0 });
        setLots(rows);
    }
    const handleNeighborhoodChange = async (event) => {
        const selectedNeighborhood = event.target.value;
        setSelectedNeighborhood(selectedNeighborhood);
        setSelectedLot('');
        loadLots(selectedNeighborhood);
        // try {
        //     const response = await fetch('/api/call-stored-procedureWithParam3', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ procedureName: 'ui_sp_getLotsByNeighborhood', param: selectedNeighborhood }),
        //     });
        //     const data = await response.json();
        //     setLots(data);
        // } catch (error) {
        //     console.error('Failed to fetch lots:', error);
        // }
    };
    const handleLotChange = (event) => {
        const selectedLot = event.target.value;
        setSelectedLot(selectedLot);
        //CentralCalls.upsertData({ oid : selectedLot }, 'phase', 'update')
        // Send selectedLot OID to parent component
        // Replace the following line with your desired code
        onLotChange(selectedLot);
    };
    const handleRecentLotSelect = (id) => {
        console.log('lcick...' + id);
        loadLotFromUrl(id);
        setShowRecentLots(false);
    };
    return (
        <div className="lot-filter-container">
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <div className="lot-filter">
                        {/* BRING BACKKKKK <div
                            onMouseEnter={() => toggleShowRecentLots(true)}
                            onMouseLeave={() => toggleShowRecentLots(false)}
                        >
                            <Tooltip placement="top"  title="Recent">
                                <IconButton className="recent-lots-button" color="primary" aria-label="save" size="medium">
                                    <FontAwesomeIcon icon={faEye} />
                                </IconButton>
                            </Tooltip>
                            {showRecentLots && (
                                <div className="recent-lots-list" onMouseEnter={() => toggleShowRecentLots(true)}
                                    onMouseLeave={() => toggleShowRecentLots(false)}>
                                    <ul>
                                        {recentLots.map((lot) => (
                                            <li key={lot.id} onClick={() => handleRecentLotSelect(lot.id)}>
                                                {lot.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div> */}

                        <select className="select-list" value={selectedBuilder} onChange={handleBuilderChange}>
                            <option value="">Select Builder</option>
                            {builders && builders.sort((a, b) => a.name.localeCompare(b.name)).map((builder) => (
                                <option key={builder.oid} value={builder.oid}>
                                    {builder.name}
                                </option>
                            ))}
                        </select>

                        <span className="marker">>></span>

                        <select className="select-list" value={selectedNeighborhood} onChange={handleNeighborhoodChange}>
                            <option value="">Select Neighborhood</option>
                            {neighborhoods && neighborhoods.sort((a, b) => a.name.localeCompare(b.name)).map((neighborhood) => (
                                <option key={neighborhood.oid} value={neighborhood.oid}>
                                    {neighborhood.name}
                                </option>
                            ))}
                        </select>
                        <span className="marker">>></span>
                        <select className="select-list" value={selectedLot} onChange={handleLotChange}>
                            <option value="">Select Lot</option>
                            {lots && lots.sort((a, b) => a.lot_num.localeCompare(b.lot_num)).map((lot) => (
                                <option key={lot.oid} value={lot.oid}>
                                    {lot.lot_num}
                                </option>
                            ))}
                        </select>
                    </div>


                </>

            )}
        </div>
    );
}
export default LotFilter;